import moment from "moment";
import Calculator from "../helper/Calculator";
import axios from "axios";

export const getTaskOfParticularSprint = async (id, user) => {
  var data = JSON.stringify({
    userList: user ? user : [],
  });

  var config = {
    method: "post",
    url: process.env.REACT_APP_NGROK_LINK + `task_management/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTaskOfFilesSprint = async (id, q) => {
  var config = {
    method: "get",
    url: process.env.REACT_APP_NGROK_LINK + `file/project/${id}?term=${q}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTaskFiles = async (id) => {
  var config = {
    method: "delete",
    url: `${process.env.REACT_APP_NGROK_LINK}file/${id}`,
    headers: {},
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postTaskOfParticularSprint = async (task) => {
  var data = JSON.stringify(task);

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}task_management/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
//remaining from here
export const postFileOfParticularSprint = async (formData) => {
  const reqOptions = {
    method: "POST",
    data: formData,
  };
  try {
    const response = await axios(
      `${process.env.REACT_APP_NGROK_LINK}file`,
      reqOptions
    );
    return await response.data;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const downloadTaskFiles = async (id) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
    },
  };

  try {
    const res = await axios(
      `${process.env.REACT_APP_NGROK_LINK}/file/${id}`,
      requestOptions
    );
    return await res.data;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const getParticularTask = async (id) => {
  try {
    const response = await axios(
      process.env.REACT_APP_NGROK_LINK + `task_management/task/${id}`
    );
    const res = await response.data;

    return res;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const updateStatusofTask = async (taskId, status_id) => {
  var data = JSON.stringify({
    status_id: status_id,
  });

  var config = {
    method: "put",
    url: `${process.env.REACT_APP_NGROK_LINK}/task_management/status/${taskId}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTask = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };
  try {
    axios(
      `${process.env.REACT_APP_NGROK_LINK}/task_management/${id}`,
      requestOptions
    );
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const AddTimeSpentOnTask = async (values) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}/activity_log/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: values,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const PostChildIssue = async (issue) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    task_id: issue.task_id,
    description: issue.description,
    status: issue.status,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    fetch(`${process.env.REACT_APP_NGROK_LINK}sub_issue`, requestOptions);
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const tasksofSprintOn = async (id) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const response = await axios(
      process.env.REACT_APP_NGROK_LINK + `task_management/kanban?id=${id}`,
      requestOptions
    );
    if (response.status == 404) {
      throw e;
    } else {
      return await response.data;
    }
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const backLogTasks = async (id) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}task_management/backlog/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const updateTasksSprintId = async (id, taskList) => {
  var data = JSON.stringify({
    taskList: taskList,
    sprintId: id,
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}/task_management/update_orphan`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const statusOfProject = async (id) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}status/${id}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const estimationTypeOfProject = async () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}estimation_type`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const addStatusOfProject = async (id) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}/status/${id}`,
    headers: {},
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const EditStatusName = async (id, updatedName) => {
  var data = JSON.stringify({
    name: updatedName,
  });

  var config = {
    method: "put",
    url: `${process.env.REACT_APP_NGROK_LINK}/status/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const addingStatusofProject = async (id, statusName) => {
  var data = JSON.stringify({
    project_id: id,
    name: statusName,
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}/status/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const editTask = async (taskId, dataParams) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_NGROK_LINK}/task_management/${taskId}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataParams,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBoard = async (id) => {
  var config = {
    method: "delete",
    url: `${process.env.REACT_APP_NGROK_LINK}/status/${id}`,
    headers: {},
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postComment = async (id, comments, taskId) => {
  var data = JSON.stringify({
    user_id: id,
    comment: comments,
    related_id: taskId,
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NGROK_LINK}/comment/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const displayComments = async (id) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}comment/${id}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const displayChildIssue = async (id) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}sub_issue/${id}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};

export const getMembersNames = async () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_NGROK_LINK}user`,
    headers: {},
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log("ERROR = ", error);
  }
};
