import axios from "axios";
export const getAllSprintsofParticularProject = async (id, q, users) => {
  var data = JSON.stringify({
    userList: users ? users : [],
  });

  var config = {
    method: "POST",
    url: `${process.env.REACT_APP_NGROK_LINK}sprints/${id}?term=${q}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const postSprint = async (sprint) => {
  var data = JSON.stringify(sprint);
  var config = {
    method: "POST",
    url: `${process.env.REACT_APP_NGROK_LINK}sprints/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteSprint = async (id) => {
  var config = {
    method: "delete",
    url: `${process.env.REACT_APP_NGROK_LINK}sprints/${id}`,
    // headers: {
    //   Authorization: "Bearer glpat-bD56y7AJPVcZosZrHNyh",
    // },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const makeSprintOn = async (id) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_NGROK_LINK}/sprints/on/${id}`,
    headers: {},
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSprint = async (data, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(data);

  try {
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await axios(
      `${process.env.REACT_APP_NGROK_LINK}sprints/${id}`,
      requestOptions
    );
    return await response.json();
  } catch (e) {
    console.log("ERROR = ", e);
  }
};
