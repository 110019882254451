import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllSprintsofParticularProject,
  postSprint,
  deleteSprint,
  makeSprintOn,
  UpdateSprint,
} from "../service/sprintService";
import moment from "moment";

const initialState = {
  isLoading: false,
  allSprints: [],
  fail: false,
  update: false,
};

//get sprints of particular project
export const getSprints = createAsyncThunk(
  "sprints/getSprints",
  async (params, thunkAPI) => {
    return getAllSprintsofParticularProject(params.id, params.q, params.filter);
  }
);





//Filter Sprints
export const FilterSprints = createAsyncThunk(
  "sprints/FilterFiles",
  async ({ search, id }, thunkAPI) => {
    if (search.length > 0) {
      const prev = await getAllSprintsofParticularProject(id, "");
      const filteredFiles = prev.data.filter((item) => {
        if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return item;
        }
      });

      return { data: filteredFiles };
    } else {
      return await getAllSprintsofParticularProject(id, "");
    }
  }
);

//post
export const postSprints = createAsyncThunk(
  "sprints/postSprints",
  async (sprint = { data }, thunkAPI) => {
    sprint = {
      ...sprint,
      start_date: moment(new Date(sprint.startDate)).format(
        "YYYY-MM-DD hh:mm:ss"
      ),
      end_date: moment(new Date(sprint.endDate)).format("YYYY-MM-DD hh:mm:ss"),
    };

    delete sprint.startDate;
    delete sprint.endDate;

    await postSprint(sprint);
    return await getAllSprintsofParticularProject(sprint.project_id, "");
  }
);
//editSprint
export const editSprint = createAsyncThunk(
  "sprints/editSprint",
  async ({ data: data, id: id }, thunkAPI) => {
    data = {
      ...data,
      start_date: moment(new Date(data.startDate)).format(
        "YYYY-MM-DD hh:mm:ss"
      ),
      end_date: moment(new Date(data.endDate)).format("YYYY-MM-DD hh:mm:ss"),
    };
    delete data.startDate;
    delete data.endDate;
    await UpdateSprint(data, id);
    return await getAllSprintsofParticularProject(data.project_id, "");
  }
);

//makeSprintOn
export const makeSprintsOn = createAsyncThunk(
  "sprints/makeSprintsOn",
  async ({ id, project_id }, thunkAPI) => {
    let prev = [...thunkAPI.getState().sprint.allSprints];
    const index = prev.findIndex((item) => {
      return item.id === id;
    });
    const updatedSprint = prev.filter((item) => {
      return item.id == id;
    });
    if (index !== -1) {
      prev.splice(index, 1);
    }
    if (updatedSprint[0].is_on) {
      updatedSprint[0] = { ...updatedSprint[0], is_on: 0 };
    } else {
      updatedSprint[0] = { ...updatedSprint[0], is_on: 1 };
    }
    await makeSprintOn(id);
    return [...prev, updatedSprint[0]].sort((a, b) => a.id - b.id);
  }
);

//delete
export const deleteSprints = createAsyncThunk(
  "sprints/deleteSprints",
  async (id, thunkAPI) => {
    let prev = [...thunkAPI.getState().sprint.allSprints];
    const index = prev.findIndex((item) => {
      return item.id === id;
    });
    if (index !== -1) {
      prev.splice(index, 1);
    }
    await deleteSprint(id);
    return [...prev];
  }
);

const sprintSlice = createSlice({
  name: "sprints",
  initialState,
  reducers: {},
  //get
  extraReducers: (builder) => {
    builder.addCase(getSprints.pending, (state, action) => {
      state.isLoading = true;
      state.fail = false;
    });
    builder.addCase(getSprints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSprints = action.payload?.data || [];
      state.fail = false;
    });
    builder.addCase(getSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });

    //Filter Files
    builder.addCase(FilterSprints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSprints = action.payload?.data || [];
      state.fail = false;
    });
    builder.addCase(FilterSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
    //post
    builder.addCase(postSprints.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.allSprints = action.payload?.data || [];
        state.fail = false;
      } else {
        state.isLoading = false;
        state.fail = true;
      }
    });
    builder.addCase(postSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
    //delete
    builder.addCase(deleteSprints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSprints = action.payload || [];
    });
    builder.addCase(deleteSprints.rejected, (state, action) => {
      state.isLoading = false;
    });
    //makeSprintOn
    builder.addCase(makeSprintsOn.pending, (state, action) => {
      state.update = true;
    });
    builder.addCase(makeSprintsOn.fulfilled, (state, action) => {
      state.update = false;
      state.allSprints = action.payload || [];
    });
    builder.addCase(makeSprintsOn.rejected, (state, action) => {
      state.update = false;
    });
    //edit
    builder.addCase(editSprint.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSprints = action.payload?.data || [];
    });
    builder.addCase(editSprint.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default sprintSlice.reducer;
