import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTaskOfParticularSprint,
  getParticularTask,
  deleteTask,
  postFileOfParticularSprint,
  getTaskOfFilesSprint,
  deleteTaskFiles,
  postTaskOfParticularSprint,
  updateStatusofTask,
  editTask,
  tasksofSprintOn,
  backLogTasks,
  updateTasksSprintId,
  deleteBoard,
  displayComments,
  getMembersNames,
} from "../service/taskService";
import { getSprints } from "./sprintSlice";
const initialState = {
  isLoading: false,
  tasksOfSprints: [],
  particularTask: [],
  loading: false,
  fail: false,
  particularTaskFile: [],
  tasksOfBoard: [],
  taskofBacklog: [],
  backlogsLoading: false,
  membersList: [],
};

export const getTasksOfSprints = createAsyncThunk(
  "tasks/tasksofSprints",
  async ({ id, filter }, thunkAPI) => {
    return getTaskOfParticularSprint(id, filter);
  }
);

//User Filtering Board
export const getTasksofBoard = createAsyncThunk(
  "tasks/tasksofboard",
  async ({ id, filter }, thunkAPI) => {
    if (filter.length > 0) {
      const res = await tasksofSprintOn(id);
      const filteredTask = res.data.filter((item) => {
        for (let i = 0; i < filter.length; i++) {
          if (item.user_tasks[0].user.id == filter[i]) {
            return item;
          }
        }
      });
      return { data: filteredTask };
    } else {
      return await tasksofSprintOn(id);
    }
  }
);

//kanban Board
export const getTasksOfSprintsOn = createAsyncThunk(
  "tasks/tasksofSprintOn",
  async (id, thunkAPI) => {
    return await tasksofSprintOn(id);
  }
);

export const getparticularTask = createAsyncThunk(
  "tasks/getparticularTask",
  async (id, thunkAPI) => {
    return getParticularTask(id);
  }
);

//deleting Tasks
export const DeleteTask = createAsyncThunk(
  "tasks/DeleteTask",
  async (id, thunkAPI) => {
    let prev = [...thunkAPI.getState().tasks.tasksOfBoard];

    const index = prev.findIndex((item) => {
      return item.id === id;
    });
    if (index !== -1) {
      prev.splice(index, 1);
    }
    await deleteTask(id);
    return [...prev];
  }
);

//updatingTasksSprintId
export const updatingTasksSprintId = createAsyncThunk(
  "tasks/updatingTasksSprintId",
  async ({ id, taskList }, thunkAPI) => {
    let prev = [...thunkAPI.getState().tasks.taskofBacklog];
    const UpdatedList = prev.filter((item) => {
      if (!taskList.includes(item.id)) {
        return item;
      }
    });
    await updateTasksSprintId(id, taskList);
    return [...UpdatedList];
  }
);

//Post Tasks
export const postTasksOfSprints = createAsyncThunk(
  "tasks/postTasksOfSprints",
  async ({ tasks, file, id }, thunkAPI) => {
    if (file) {
      const response = await postTaskOfParticularSprint(tasks);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("task_id", response.data.details.id);
      await postFileOfParticularSprint(formData);
    } else {
      await postTaskOfParticularSprint(tasks);
    }
  }
);

//get files

export const getFilesOfSprints = createAsyncThunk(
  "tasks/getFilesOfSprints",
  async (params, thunkApi) => {
    return getTaskOfFilesSprint(params.id, params.q);
  }
);

export const FilterFiles = createAsyncThunk(
  "task/FilterFiles",
  async ({ search, id }, thunkAPI) => {
    let prev = [...thunkAPI.getState().tasks.filesOfSprints];
    if (search.length > 0) {
      const filteredFiles = prev.filter((item) => {
        if (
          item["file_path"].toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          return item;
        }
      });
      return { data: filteredFiles };
    } else {
      return await getTaskOfFilesSprint(id, "");
    }
  }
);

//delete files

export const deleteFiles = createAsyncThunk(
  "tasks/deleteFiles",
  async (id, thunkAPI) => {
    let prev = [...thunkAPI.getState().tasks.filesOfSprints];
    const index = prev.findIndex((item) => {
      return item.id == id;
    });
    if (index !== -1) {
      prev.splice(index, 1);
    }
    await deleteTaskFiles(id);
    return [...prev];
  }
);

export const UpdateTaskStatus = createAsyncThunk(
  "tasks/UpdateTaskStatus",
  async ({ task_id, updatedStatus, sprint_id }, thunkAPI) => {
    await updateStatusofTask(task_id, updatedStatus);
    return getTaskOfParticularSprint(sprint_id);
  }
);

// edit task in task modal
export const EditTask = createAsyncThunk(
  "tasks/EditTask",
  async ({ task_id, sprint_id, status_id, dataParams }, thunkAPI) => {
    await editTask(task_id, dataParams);
    return getTaskOfParticularSprint(sprint_id);
  }
);

export const FilterTask = createAsyncThunk(
  "task/FilterTask",
  async ({ search, id }, thunkAPI) => {
    const prev = await tasksofSprintOn(id);
    if (search.length > 0) {
      const filteredTask = prev.data.filter((item) => {
        if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return item;
        }
      });
      return { data: filteredTask };
    } else {
      return await tasksofSprintOn(id);
    }
  }
);
//TasksofBackLog
export const backlogTasks = createAsyncThunk(
  "task/backlogTasks",
  async (id, thunkAPI) => {
    return await backLogTasks(id);
  }
);
//DeletingBoard and Updating their Status
export const deleteBoardAndUpdateTasks = createAsyncThunk(
  "task/deleteBoardAndUpdateTasks",
  async ({ deleteId, statusUpdateId }, thunkAPI) => {
    let prev = [...thunkAPI.getState().tasks.tasksOfBoard];
    const UpdatedTasks = await Promise.all(
      prev.map(async (item) => {
        if (item.status_id == deleteId) {
          const task = {
            ...item,
            status_id: statusUpdateId,
          };
          await updateStatusofTask(item.id, statusUpdateId);
          return task;
        } else {
          return item;
        }
      })
    );
    await deleteBoard(deleteId);
    return [...UpdatedTasks];
  }
);

//get total number of members of company
export const listOfMembers = createAsyncThunk(
  "task/listOfMembers",
  async () => {
    return await getMembersNames();
  }
);

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getTasksOfSprints
    builder.addCase(getTasksOfSprints.pending, (state, action) => {
      state.isLoading = true;
      state.fail = false;
    });
    builder.addCase(getTasksOfSprints.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.tasksOfSprints = action.payload?.data || [];
        state.fail = false;
      } else {
        state.isLoading = false;
        state.fail = true;
      }
    });
    builder.addCase(getTasksOfSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
    //backlogTasks
    builder.addCase(backlogTasks.pending, (state, action) => {
      state.backlogsLoading = true;
    });
    builder.addCase(backlogTasks.fulfilled, (state, action) => {
      state.backlogsLoading = false;
      state.taskofBacklog = action.payload?.data || [];
    });
    builder.addCase(backlogTasks.rejected, (state, action) => {
      state.backlogsLoading = false;
    });

    builder.addCase(updatingTasksSprintId.fulfilled, (state, action) => {
      state.backlogsLoading = false;
      state.taskofBacklog = action.payload || [];
    });
    builder.addCase(updatingTasksSprintId.rejected, (state, action) => {
      state.backlogsLoading = false;
    });
    //getParticularTask
    builder.addCase(getparticularTask.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getparticularTask.fulfilled, (state, action) => {
      state.loading = false;
      state.particularTask = action.payload?.data || [];
      state.particularTaskFile = action.payload?.meta || [];
    });
    builder.addCase(getparticularTask.rejected, (state, action) => {
      state.loading = false;
    });
    //deleteTask
    builder.addCase(DeleteTask.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteTask.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfBoard = action.payload || [];
    });
    builder.addCase(DeleteTask.rejected, (state, action) => {
      state.isLoading = false;
    });
    //Filter Task
    builder.addCase(FilterTask.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfBoard = action.payload?.data || [];
    });
    builder.addCase(FilterTask.rejected, (state, action) => {
      state.isLoading = false;
    });
    // Filter Files
    builder.addCase(FilterFiles.pending, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(FilterFiles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filesOfSprints = action.payload?.data || [];
    });
    builder.addCase(FilterFiles.rejected, (state, action) => {
      state.isLoading = false;
    });
    //get files
    builder.addCase(getFilesOfSprints.pending, (state, action) => {
      state.isLoading = true;
      state.fail = false;
    });
    builder.addCase(getFilesOfSprints.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.filesOfSprints = action.payload?.data || [];
        state.fail = false;
      } else {
        state.isLoading = false;
        state.fail = true;
      }
    });
    builder.addCase(getFilesOfSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
    //delete files
    builder.addCase(deleteFiles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFiles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filesOfSprints = action.payload || [];
    });
    builder.addCase(deleteFiles.rejected, (state, action) => {
      state.isLoading = false;
    });
    //post task of sprints
    builder.addCase(postTasksOfSprints.pending, (state, action) => {
      state.isLoading = true;
      state.fail = false;
    });
    builder.addCase(postTasksOfSprints.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.tasksOfSprints.push(action.payload?.data);
        state.fail = false;
      } else {
        state.isLoading = false;
        state.fail = false;
      }
    });

    builder.addCase(postTasksOfSprints.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
    //UpdateStatus
    builder.addCase(UpdateTaskStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(UpdateTaskStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfSprints = action.payload?.data || [];
    });
    builder.addCase(UpdateTaskStatus.rejected, (state, action) => {
      state.isLoading = false;
    });
    // Edit Task
    builder.addCase(EditTask.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(EditTask.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfSprints = action.payload?.data || [];
    });
    builder.addCase(EditTask.rejected, (state, action) => {
      state.isLoading = false;
    });
    //kanbanBoard
    builder.addCase(getTasksOfSprintsOn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTasksOfSprintsOn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfBoard = action.payload?.data || [];
    });
    builder.addCase(getTasksOfSprintsOn.rejected, (state, action) => {
      state.isLoading = false;
    });
    //filtering Board
    builder.addCase(getTasksofBoard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfBoard = action.payload?.data || [];
    });
    builder.addCase(getTasksofBoard.rejected, (state, action) => {
      state.isLoading = false;
    });
    //Deleting Board and Updating Tasks
    builder.addCase(deleteBoardAndUpdateTasks.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBoardAndUpdateTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tasksOfBoard = action.payload || [];
    });
    builder.addCase(deleteBoardAndUpdateTasks.rejected, (state, action) => {
      state.isLoading = false;
    });

    //get total number of user of company
    builder.addCase(listOfMembers.pending, (state, action) => {
      state.isLoading = true;
      state.fail = false;
    });
    builder.addCase(listOfMembers.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.membersList = action.payload?.data || [];
        state.fail = false;
      } else {
        state.isLoading = false;
        state.fail = true;
      }
    });
    builder.addCase(listOfMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.fail = true;
    });
  },
});

export default taskSlice.reducer;
