// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getData = createAsyncThunk(
  "permissions/getData",
  async (params, thunkAPI) => {
    try {
      // const userId = JSON.parse(localStorage.getItem("userData"))?.id;
      const url =
        process.env.REACT_APP_NGROK_LINK +
        `project?page=${params.page}&size=${params.perPage}&term=${params.q}&status=${params.status}`;
      const response = await axios(url);
      if (response.status == 404) {
        throw e;
      } else {
        return response;
      }
    } catch (e) {
      throw e;
    }
  }
);
export const allProjectListSlice = createSlice({
  name: "permissions",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
    fail: false,
    loader: false,
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.loader = true;
      state.fail = false;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loader = false;
      state.data = action.payload.data;
      state.total = action.payload.data.meta.lastPage;
      state.fail = false;
    });
    builder.addCase(getData.rejected, (state, action) => {
      state.loader = false;
      state.fail = true;
    });
  },
});

export const { selectPermission } = allProjectListSlice.actions;

export default allProjectListSlice.reducer;
