import axios from "axios";
export const getAllProjects = async () => {
  try {
    const id = JSON.parse(localStorage.getItem("userData"))?.id;
    const response = await axios(
      process.env.REACT_APP_NGROK_LINK + `project/recent?size=4&userId=${id}`
    );
    if (response.status == 404) {
      throw e;
    } else {
      return response;
    }
  } catch (e) {
    console.log("ERROR = ", e);
    throw e;
  }
};
export const getParticularProject = async (id) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_NGROK_LINK + `project/${id}`
    );
    return response;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

//edit project
export const editProjectSettings = async (id, data) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_NGROK_LINK + `project/${id}`,
      data
    );
    return response;
  } catch (error) {
    console.log("ERROR : ", error);
  }
};

export const addUserInProject = async (project_id, user_id) => {

  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_NGROK_LINK}project/${project_id}/user/${user_id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
