import axios from "axios";
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getData = createAsyncThunk(
  "tasks/getData",
  async (params, thunkAPI) => {
    const reqOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_NGROK_LINK +
        `task_management/userTask?page=${params.page}&size=${params.perPage}&term=${params.q}&status=${params.status}`,
        { project_id: params.body.project_id, user_id: 71 },
        reqOptions
      );
      if (response.status == 404) {
        throw new Error("Not Found");
      } else {
        return await response;
      }
    } catch (e) {
      console.log("ERROR = ", e);
      throw e;
    }
  }
);

export const taskListSlice = createSlice({
  name: "appInvoice",
  initialState: {
    data: [],
    allData: [],
    loading: false,
    fail: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.loading = true;
      state.fail = false;
    });

    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload) {
        const { data } = action.payload;
        const updatedData = data.data.map((item) => {
          return {
            ...item,
            sprint_id: item.id,
            id: item["tasks_managements.id"],
          };
        });
        state.data = updatedData;
        state.total = action.payload.data.meta.lastPage;
        state.loading = false;
        state.fail = false;
      } else {
        state.total = action.payload.data.meta.lastPage;
        state.loading = false;
        state.fail = true;
      }
    });

    builder.addCase(getData.rejected, (state, action) => {
      state.loading = false;
      state.fail = true;
    });
  },
});

export default taskListSlice.reducer;
