import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCurrentUserProfile, getUserProfile } from '../service/profileService'

const initialState = {
  isLoading: false,
  allProfile: {},
}

//get
export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (id, thunkAPI) => {
    let result = await getUserProfile(id)
    return result
  }
)

export const getCurrentProfile = createAsyncThunk(
  'profile/getCurrentProfile',
  async (thunkAPI) => {
    let result = await getCurrentUserProfile()
    return result
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  //get
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false
      state.allProfile = action.payload?.data || []
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(getCurrentProfile.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCurrentProfile.fulfilled, (state, action) => {
      state.isLoading = false
      state.allProfile = action.payload?.data || []
    })
    builder.addCase(getCurrentProfile.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default profileSlice.reducer
