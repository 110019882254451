import axios from "axios";

export const getUserProfile = async (id) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const response = await axios(
      `${process.env.REACT_APP_NGROK_LINK}user/dummy/${id}`,
      requestOptions
    );
    return await response.data;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};

export const getCurrentUserProfile = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const response = await axios(
      `${process.env.REACT_APP_NGROK_LINK}user/profile`,
      requestOptions
    );
    return await response.data;
  } catch (e) {
    console.log("ERROR = ", e);
  }
};
