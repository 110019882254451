import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllProjects,
  getParticularProject,
  addUserInProject
} from "../service/projectService";

const initialState = {
  isLoading: false,
  loading: false,
  projectsList: [],
  projectDetail: null,
  loading: false,
  fail: false,
};

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (thunkAPI) => {
    return getAllProjects();
  }
);

export const particularProject = createAsyncThunk(
  "projects/particularProject",
  async (id, thunkAPI) => {
    return getParticularProject(id);
  }
);

export const addUsers = createAsyncThunk(
  "projects/addUsers",
  async ({ project_id, user_id }, thunkAPI) => {
    await addUserInProject(project_id, user_id);
    return getParticularProject(project_id);
  }
);

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Projects
    builder.addCase(getProjects.pending, (state, action) => {
      state.loading = true;
      state.fail = false;
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.loading = false;
      state.projectsList = action.payload?.data || [];
      state.fail = false;
    });

    builder.addCase(getProjects.rejected, (state, action) => {
      state.loading = false;
      state.fail = true;
    });

    //Particular Projects
    builder.addCase(particularProject.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(particularProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectDetail = action.payload?.data || {};
    });
    builder.addCase(particularProject.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectDetail = action.payload?.data || {};
    });
    builder.addCase(addUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { addUserInProjectAction, setLoading } = projectSlice.actions;

export default projectSlice.reducer;
