// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import users from "../views/store";
import projects from "./projectSlice";
import allProjectList from "../views/projectComponent/projectList/store"; //replace permissions
import sprint from "./sprintSlice";
import tasks from "./taskSlice";
import profile from "./profileSlice";
import taskList from "../views/apps/taskList/store";
import allProjectTasks from "../views/apps/allProjectTasks/store";

const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  taskList,
  allProjectTasks,
  allProjectList,
  projects,
  sprint,
  tasks,
  profile,
};

export default rootReducer;
